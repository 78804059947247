/* Global Styling here */

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.customScrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: "red";
}

.hideScollbar::-webkit-scrollbar {
  display: none;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary);
}

.absoluteCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .glassBackground {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);

    background-color: rgb(206, 198, 183, 0.05);
  }

  .warning {
    display: none;
  }
}

.slick-slide {
  margin: 0 27px;
  background: red;
}

.swiper-button-next::after {
  font-size: 18px;
  font-weight: bold;
}

.swiper-button-next {
  background-color: white;
  border-radius: 100%;
  font-size: 15px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  box-shadow: var(--chakra-shadows-lg);
}

.swiper-button-prev::after {
  font-size: 18px;
  font-weight: bold;
}

.swiper-button-prev {
  background-color: white;
  border-radius: 100%;
  font-size: 15px;
  height: 42px;
  width: 42px;
  margin-left: 10px;
  box-shadow: var(--chakra-shadows-lg);
}

/* the parent */
/* .slick-list {
  margin: 0 -27px;
} */

.customIcon path {
  stroke: white;
}